<template>
  <div class="home"></div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'

import { useRoute } from 'vue-router'

import service from '@/utils/service'

import { ethers } from 'ethers'

import { Base64 } from 'js-base64'

import { buildContract, exceptionHandling } from '@/utils'

const route = useRoute()

onBeforeMount(async () => {
  const data = route.query.code
  const account = route.query.account
  const decoded = Base64.decode(data)

  const list = JSON.parse(decoded)

  try {
    for (const item of list) {
      const contract = await buildContract(
        item.blockchainId,
        item.contractAddress,
        account
      )

      const inputs = parseInputs(item.inputs)

      console.info('inputs:', inputs)
      console.info('methodName:', item.methodName)
      console.info('value:', item.value)

      const tx = await contract[item.methodName](...inputs, {
        value: ethers.parseEther(item.value),
      })

      await tx.wait()

      console.info(tx)
    }

    window.close()
  } catch (e) {
    console.error(e)
    exceptionHandling(e)
  }
})

// 解析输入参数并转换为智能合约所需的数据类型

function parseInputs(inputs) {
  return inputs.map((input) => {
    const { type, value } = input

    // 模糊匹配整数类型
    if (/^uint\d+$/.test(type) || /^int\d+$/.test(type)) {
      return ethers.toBigInt(value) // 6.x 版本使用 toBigInt
    }

    // 模糊匹配数组的整数类型
    if (/^uint\d+\[\]$/.test(type) || /^int\d+\[\]$/.test(type)) {
      return value.map((v) => ethers.toBigInt(v))
    }

    switch (type) {
      // Boolean type
      case 'bool':
        return value === 'true' // 直接处理为布尔值

      // Address type
      case 'address':
        return ethers.getAddress(value) // 6.x 版本中仍然使用 getAddress

      // String type
      case 'string':
        return value.toString()

      // Bytes types
      case 'bytes':
        return ethers.getBytes(value) // 6.x 版本使用 getBytes 处理动态字节数组
      case 'bytes32':
        return ethers.zeroPadValue(value, 32) // 使用 zeroPadValue 确保是 32 字节

      // Static byte arrays (bytes1 to bytes32)
      default:
        if (/^bytes\d+$/.test(type)) {
          const byteSize = parseInt(type.replace('bytes', ''))
          return ethers.zeroPadValue(value, byteSize) // zeroPadValue 确保长度正确
        }

        // Handling arrays of static bytes (e.g., bytes1[], bytes32[])
        if (/^bytes\d+\[\]$/.test(type)) {
          const byteSize = parseInt(type.replace('[]', '').replace('bytes', ''))
          return value.map((v) => ethers.zeroPadValue(v, byteSize))
        }

        // Handling arrays of other types like address[], bool[], string[], etc.
        if (type.endsWith('[]')) {
          const elementType = type.slice(0, -2)
          return value.map(
            (v) => parseInputs([{ type: elementType, value: v }])[0]
          )
        }

        throw new Error(`Unsupported type: ${type}`)
    }
  })
}

// 处理解码后的数据，将 BigInt 或 Ethers.js 的 BigNumberish 转换为字符串
// 处理解码后的数据，将 BigInt 转换为字符串
function handleBigInt(data) {
  if (Array.isArray(data)) {
    // 如果是数组，递归处理每个元素
    return data.map(handleBigInt)
  } else if (typeof data === 'bigint') {
    // 如果是 JavaScript 原生的 BigInt 类型，转换为字符串
    return data.toString()
  } else if (ethers.isHexString(data)) {
    // 处理返回的十六进制字符串类型
    return data
  } else if (typeof data === 'object' && data !== null) {
    // 如果是对象，递归处理对象的每个属性
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, handleBigInt(value)])
    )
  }
  // 其他数据类型保持不变
  return data
}
</script>
