/** @format */

const axios = require('axios')

const instance = axios.create({
  baseURL: 'https://app.hyperagi.network/api',
  timeout: 10000,
})
// 请求拦截器
instance.interceptors.request.use((request) => {
  console.info(
    `Starting Request: ${request.method.toUpperCase()} ${request.url}`
  )
  console.info(`Request Data: ${JSON.stringify(request.data)}`)
  return request
})

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    console.info(response)
    console.info(`Response: ${response.status} ${response.statusText}`)
    console.info(`Response Data: ${JSON.stringify(response.data)}`)

    return response.data
  },
  (error) => {
    console.error(`Error: ${error.message}`)
    if (error.response) {
      console.error(
        `Response Error Data: ${JSON.stringify(error.response.data)}`
      )
    }
    return Promise.reject(error)
  }
)

console.log('Current baseURL:', instance.defaults.baseURL)

export default instance
